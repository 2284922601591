export const enum Buildings {
  BlackSmith = 'blacksmith',
  Dock = 'dock',
  GoldOreSmelter = 'gold_ore_smelter',
  Jewelry = 'jewelry',
  Sawmill = 'sawmill',
  ToymakerShop = 'toymaker_shop',
  Plantation = 'plantation',
  Warehouse = 'warehouse',
  Townhall = 'townhall',
  IronOreMine = 'iron_ore_mine',
  CoalMine = 'coal_mine',
  GoldOreMine = 'gold_ore_mine',
  IronOreSmelter = 'iron_ore_smelter'
}
