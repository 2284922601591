<template>
  <div class="seasonStartContainer">
    <UIHighlightedCard>
      <div class="tokensWrapper">
        <div v-for="token of data?.config?.slice(0, 3).reverse()" :key="token.tokenAddress" class="tokenItem">
          <img
            :width="100"
            :src="tokens[tokenMetaData(blockchain.contracts, token.tokenAddress)?.name.contract as Tokens] as string"
            alt=""
          />
          <span>{{ $t(tokenMetaData(blockchain.contracts, token.tokenAddress)?.label) }}</span>
        </div>
      </div>
    </UIHighlightedCard>
    <button class="btn-primary openBtn" @click="openHandler">{{ $t('openSeason') }}!</button>
  </div>
</template>

<script lang="ts" setup>
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import tokens from '~/public/img/tokens';
import { SeasonModalView } from '~/types/season';
import type { Tokens } from '~/utils/constants';
const { blockchain } = useEnvs();
const emit = defineEmits<{ updateModalView: [modalView: SeasonModalView] }>();
const { data } = useNuxtData('seasonsConfig');
const { address } = useWeb3ModalAccount();

const seasonStartClicked = useCookie(`seasonStartClicked:${address.value}`);
seasonStartClicked.value = seasonStartClicked.value || `false`;

const openHandler = () => {
  emit('updateModalView', SeasonModalView.burnTokens);
  seasonStartClicked.value = 'true';
};
</script>

<style lang="scss" scoped>
.seasonStartContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 25px;

  .tokensWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin: 0 40px;

    .tokenItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      color: white;
      font-family: Grenze, sans-serif;
    }
  }

  .openBtn {
    font-size: 20px;
    height: 62px;
    padding: 0 40px;
  }
}
</style>
