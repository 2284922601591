<template>
  <div class="scroll-wrapper">
    <div class="icon-scroll">
      <div class="scroll-dot" />
    </div>
  </div>
</template>
<style lang="scss">
.scroll-wrapper {
  width: 100px;
  height: 100px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-image: linear-gradient(rgba(0, 0, 0, 0), #052430 35%);
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: absolute;

  @media screen and (max-width: 991px) {
    width: 80px;
    height: 96px;
  }
}

.icon-scroll {
  width: 40px;
  height: 70px;
  border: 2px solid #fff;
  border-radius: 25px;
  position: relative;
}

.scroll-dot {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  bottom: auto;
  left: 50%;
  right: auto;

  opacity: 1;
  transform: translate3d(-50%, 40px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transform-style: preserve-3d;
  will-change: opacity, transform;
  animation: dot 1.5s ease-out infinite reverse;
}

@keyframes dot {
  0% {
    transform: translate3d(-50%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  }
  50% {
    transform: translate3d(-50%, 35px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  }
  80% {
    opacity: 0.3;
    transform: translate3d(-50%, 40px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  }
  100% {
    opacity: 0;
    transform: translate3d(-50%, 40px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  }
}
</style>
