<template>
  <div class="seasonModalSwitcherContainer">
    <div
      v-for="option of SEASON_SWITCHER_MODAL_OPTIONS"
      :key="option.name"
      class="option"
      :class="{ active: $props.modalView === option.name }"
      @click="emit('updateModalView', option.name)"
    >
      <img width="57" :src="option.iconSrc" alt="" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { SeasonModalView } from '~/types/season';
import { SEASON_SWITCHER_MODAL_OPTIONS } from '~/utils/constants';

const emit = defineEmits<{ updateModalView: [modalView: SeasonModalView] }>();
defineProps<{ modalView: SeasonModalView }>();
</script>
<style lang="scss" scoped>
.seasonModalSwitcherContainer {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  .option {
    display: flex;
    align-items: center;
    padding: 20px 0;
    border: 1px solid #1e353e;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background: linear-gradient(to right, #425b6a 0%, #0e1b25 100%);
    cursor: pointer;

    &.active {
      background: linear-gradient(to right, #0083ad 0%, #0e1b25 100%);
      cursor: auto;
    }
  }
}
</style>
