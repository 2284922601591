import type { InfoModal } from '~/utils/constants';

export const infoModal: InfoModal[] = [
  {
    icon: '/img/seasonInfoCards/Iconsseason1.svg',
    textKey: 'seasonInfoItemText0'
  },
  {
    icon: '/img/seasonInfoCards/Iconsseason2.svg',
    textKey: 'seasonInfoItemText1'
  },
  {
    icon: '/img/seasonInfoCards/Iconsseason3.svg',
    textKey: 'seasonInfoItemText2'
  }
];
