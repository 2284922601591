<template>
  <div class="confirm-popup">
    <div class="overlay" @click="handleToggleDescriptionModalInjected" />
    <div class="confirm-content">
      <h2 class="confirm-content-title">{{ $t('craftingDescriptionTitle') }}</h2>
      <div class="confirm-close-btn" @click="handleToggleDescriptionModalInjected" />

      <div>
        <p class="confirm-content-confirm-text" v-html="$t('craftingDescription')" />
      </div>
      <button class="btn-primary continue" @click="handleToggleDescriptionModalInjected">
        {{ $t('continue') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const handleToggleDescriptionModalInjected = inject<() => void>('handleToggleDescriptionModal');
</script>

<style lang="scss" scoped>
.confirm-popup {
  position: absolute;
  top: 0;
  left: 0;

  z-index: 1999;
  height: 100%;
  width: 100%;

  overflow: hidden;
  border-radius: 15px;

  display: flex;
}

.confirm-close-btn {
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;
  z-index: 9999;
  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  &:hover {
    opacity: 0.6;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
}

.confirm-content {
  position: relative;
  z-index: 3;
  background-color: rgba(2, 27, 38);
  width: 100%;
  max-width: 683px;
  margin: auto;
  padding: 10px 30px 80px 30px;
  border-radius: 10px;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  &-title {
    font-family: Grenze;
    font-size: 32px;
    font-weight: 700;
    line-height: 47.36px;
    text-align: center;
    margin: 0;
    margin-bottom: 25px;
  }

  &-confirm-text {
    font-family: Grenze;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    max-width: 577px;
    margin: auto;
  }

  .btn-primary {
    display: block;
    margin: auto;
    width: 200px;

    &.continue {
      width: 100%;
      color: white;
      border-color: white;
      background: transparent;
      margin: 0;
    }
  }
}
</style>
