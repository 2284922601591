<template>
  <UIModalInnerContentWrapper ref="scrollContainer" class="contentWrapperExtraStyle">
    <div class="leaderboardContainer">
      <div v-if="data && Object.keys(data).length > 0 && !isNoPrizeSticky" class="prize">
        <span>{{ $t('seasonGrandPrize') }}:</span>
        <div v-for="(tokenAddress, i) of tokenAddresses" :key="tokenAddress" class="prizeItem">
          <img
            width="36"
            :src="tokens?.[tokenMetaData(blockchain.contracts, tokenAddress)?.name.contract as Tokens] as string"
            alt=""
          />
          <span v-if="!rewardsConfig[i][4]">{{ prizesPool[i] }}</span>
        </div>
      </div>
      <div
        v-else-if="data && isNoPrizeSticky && leaderboardData?.length > Object.keys(data).length"
        class="noPrizeBanner filled"
      >
        {{ $t('noPrize') }}
      </div>
      <div v-else style="height: 45px; width: 100%; position: sticky; top: 0; background: #040e13"></div>

      <div
        v-if="
          data &&
          Object.keys(data).length &&
          leaderboardData?.toString()?.length > 0 &&
          leaderboardData.length > Object.keys(data).length
        "
        ref="noPrizeEl"
        class="noPrize"
        :style="{ order: Object.keys(data).length + 1 }"
      >
        {{ $t('noPrize') }}
      </div>
      <div v-if="isShowStickyBgTop" class="stickyBgTop"></div>

      <SeasonLeaderboardItem
        v-for="(item, i) of leaderboardData"
        :key="item[0]"
        :address="item[0]"
        :rewards="data?.[i + 1]"
        :style="{ order: i + 1 }"
        :score="BigNumber(item[1]).toNumber()"
        :position="i + 1"
        :own="item[0] === address"
        :leaderboard-length="leaderboardData.length"
        :class="{
          ownPosition: item[0] === address,
          prizePosition: i < Object.keys(data || {}).length,
          firstPlaces: i < 7
        }"
        @change-show-sticky-bg-top="
          (isShow) => {
            isShowStickyBgTop = isShow;
          }
        "
        @change-show-sticky-bg-bottom="
          (isShow) => {
            isShowStickyBgBottom = isShow;
          }
        "
      />
      <div v-if="isShowStickyBgBottom" :style="{ order: leaderboardData.length + 4 }" class="stickyBgBottom"></div>
    </div>
  </UIModalInnerContentWrapper>
</template>
<script setup lang="ts">
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { ZeroAddress } from 'ethers';
import { apiUrls, Tokens } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';
import tokens from '~/public/img/tokens';
import type { Reward } from '~/types/season';
const emit = defineEmits(['loadingSwitcher']);
const { blockchain, apiUrl } = useEnvs();
const { getContractReadOnly } = useAbiAccess();
const { address } = useWeb3ModalAccount();
const currentSeasonId = inject<number>('currentSeasonId');

const noPrizeEl = ref<HTMLElement | null>(null);
const scrollContainer = ref<HTMLElement | null>(null);

const isShowStickyBgTop = ref(false);
const isShowStickyBgBottom = ref(false);

const isNoPrizeSticky = ref(false);

const { data } = useFetch<Record<string, Reward[]>>(
  apiUrls.seasons.positionsRewards(BigNumber(currentSeasonId || 0).toNumber()),
  {
    baseURL: apiUrl,
    immediate: true,
    watch: [address],
    onRequest() {
      emit('loadingSwitcher');
    },
    onResponse() {
      emit('loadingSwitcher');
    }
  }
);

const seasonContract = await getContractReadOnly('season', blockchain.contracts.season);
const seasonYearContract = await getContractReadOnly(
  'SEASON_REWARDS_YEAR_1',
  blockchain.contracts.SEASON_REWARDS_YEAR_1
);

const leaderboardData = await seasonContract?.getUserScoresBySeason(currentSeasonId, ZeroAddress, 1000);
const rewardsConfig = await seasonYearContract?.getSeasonRewardsConfig(currentSeasonId);
const tokenAddresses = rewardsConfig?.map((item: string[]) => item?.[0]);

const prizesPool = computed(() => {
  if (!data.value) return;
  return tokenAddresses.map((item: string) =>
    Object.values(data.value || {})
      .flat()
      .filter((entry) => entry.tokenAddress === item)
      .reduce((prev, cur) => prev + cur.amount, 0)
  );
});

onMounted(() => {
  if (noPrizeEl.value) {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].rootBounds &&
          entries[0].intersectionRect.bottom <= entries[0].rootBounds.top + 50 &&
          entries[0].intersectionRect.top === entries[0].rootBounds.top
        ) {
          isNoPrizeSticky.value = true;
        } else {
          isNoPrizeSticky.value = false;
        }
      },
      { threshold: [0.5, 0.75, 1], root: noPrizeEl.value?.parentNode?.parentNode as HTMLElement }
    );

    intersectionObserver.observe(noPrizeEl.value as HTMLElement);
  }
});
</script>

<style lang="scss" scoped>
.contentWrapperExtraStyle {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.leaderboardContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 900px) {
    width: fit-content;
  }

  .prizePosition {
    border-color: #867927;
  }

  .filled {
    background: linear-gradient(180deg, #071821 0%, #040e13 100%);
  }

  .prize {
    display: flex;
    gap: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background: linear-gradient(180deg, #071821 0%, #040e13 100%);
    z-index: 3;

    & * {
      color: #ffce1e;
      font-family: Grenze, sans-serif;
      font-size: 22px;
    }
  }

  .noPrizeBanner,
  .noPrize {
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: #88e1ff;
  }

  .noPrizeBanner {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  .prizeItem {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}

.stickyBgTop,
.stickyBgBottom {
  position: sticky;
  width: 100%;
  background: #040e13;
  animation: fade-in 0.3s ease-in forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.stickyBgTop {
  top: 35px;
  height: 50px;
}

.stickyBgBottom {
  bottom: -28px;
  height: 60px;
}
</style>

<style>
.ownPosition {
  z-index: 1;

  position: sticky;
  top: 35px;
  bottom: -20px;
  z-index: 2;
}
</style>
