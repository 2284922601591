<template>
  <div class="modalInnerWrapper" :class="{ 'new-year': store.isNewYearTheme }">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const store = useMainStore();
</script>

<style lang="scss" scoped>
.modalInnerWrapper {
  z-index: 2;
  border-radius: 10px;
  border: 1px solid #1e353e;
  background: #0d242f;
  padding: 28px;
  width: 100%;
  overflow: auto;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.7));
  max-height: 40dvh;
  position: relative;
}

.modalInnerWrapper.new-year {
  background-color: rgba(0.9, 0.1, 0.13, 0.25);
  filter: none;
  backdrop-filter: blur(2px);
  border: 1px solid #1e353e78;
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  display: block;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
}
</style>
