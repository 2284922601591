import type { Buildings } from '~/utils/constants/buildings';

export enum RecipeIngredientAction {
  BURN = 'BURN',
  VERIFIED_TRANSFER = 'VERIFIED_TRANSFER',
  TRANSFER = 'TRANSFER'
}

export enum CraftingItemTab {
  RECIPE = 'recipe',
  CRAFTING = 'crafting'
}

export interface RecipeProduct {
  productId: number;
  tokenAddress: string;
  quantityMin: string;
  quantityMax: string;
  nftValueMax: null | number;
  nftValueMin: null | number;
}

export interface RecipeIngredient {
  ingredientId: number;
  tokenAddress: string;
  quantity: string;
  action: RecipeIngredientAction;
}

export interface RecipeProps {
  recipeId: string;
  duration: number;
  activated: boolean;
  ingredients: RecipeIngredient[];
  products: RecipeProduct[];
  maxQueue?: number;
}

export interface RecipeViewersCount {
  viewersCount: number;
}

export interface RunningRecipeProps {
  userAddress: string;
  multiplyModifier: number;
  craftStartTime: string;
  craftEndTime: string;
  craftDuration: number;
  userRecipeIndex: number;
}

export interface RecipesPendingProps {
  transactionHash: string;
}

export interface BuildingProps {
  buildingName: string;
  buildingKey: Buildings;
  buildingHash: string;
  buildingId: string;
  descriptionKey: string;
}

export interface ActivityItemProps {
  tokenAddress: string;
  amount: number;
  timestamp: string;
}

export interface UserCraftingActivityProps {
  recipeId: string;
  ingredients: ActivityItemProps[];
  products: ActivityItemProps[];
}

export interface UserActivityAvailableForClaim {
  date: number;
  total: number;
  acquired: number;
  available: number;
  buildingHash: string;
  id: number;
}

export interface FormattedActivityItemProps {
  date: number;
  total: number;
  acquired: number;
  available: number;
  buildingHash: string;
  id: number;
}

export interface ActiveUsersModalProps {
  craftingViewersCount: number;
}
