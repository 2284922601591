<template>
  <div
    class="recipe-row"
    :class="[isUsersRecipe && 'usersRecipe', props.transactionHash && 'inProgress', isDone && 'isDone']"
  >
    <div v-if="props.recipe" class="recipe-row-info">
      <div class="recipe-row-token-info">
        <img :src="tokens[props.tokenMetadata.name.contract as Tokens] as string" :alt="props.tokenMetadata.label" />
        <h3>{{ $t(props.tokenMetadata.label) }} ({{ props.recipe.multiplyModifier }})</h3>
      </div>

      <div v-if="!isDone">
        <div v-if="isUsersRecipe">
          <p class="recipe-row-time-data">
            {{ $t('craftingStartsIn') }}:

            <span>{{ time }}</span>
          </p>
        </div>
        <div v-else>
          <p>{{ time }}</p>
        </div>
      </div>

      <div v-else class="recipe-row-done">
        <p>{{ $t('craftingTokensReceived') }}</p>
        <div class="recipe-row-close" />
      </div>
    </div>
    <div v-else-if="props.transactionHash" class="recipe-row-info">
      <div class="recipe-row-token-info">
        <img :src="tokens[props.tokenMetadata.name.contract as Tokens] as string" :alt="props.tokenMetadata.label" />
        <h3>{{ $t(props.tokenMetadata.label) }}</h3>
      </div>
      <div class="recipe-row-queue">
        <div class="recipe-row-time" />
        <a :href="transactionURLRef" target="_blank">
          <div class="recipe-row-link"></div>
        </a>
      </div>
    </div>
    <div v-if="!isDone" class="recipe-row-progress"></div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import useEnvs from '~/composables/useEnvs';

import { Tokens, type TTokensConfigItem } from '~/utils/constants';
import type { RunningRecipeProps } from '~/types/crafting';
import tokens from '~/public/img/tokens';

const props = defineProps<{
  runningRecipe?: RunningRecipeProps;
  recipe?: RunningRecipeProps;
  tokenMetadata: TTokensConfigItem;
  transactionHash?: string;
}>();

const { blockchain } = useEnvs();
const { address } = useWeb3ModalAccount();

const transactionURLRef = ref(`${blockchain.blockScanUrl}/tx/${props.transactionHash}`);

const isUsersRecipe = address.value === props.recipe?.userAddress;
const [hStart, mStart] = formateDate(props.recipe?.craftStartTime ?? '').split(':');
const [hEnd, mEnd] = formateDate(props.recipe?.craftEndTime ?? '').split(':');

const time = `${formatToZeroPrefixedNumber(hStart.trim())}h ${formatToZeroPrefixedNumber(mStart)}m - ${formatToZeroPrefixedNumber(hEnd.trim())}h ${formatToZeroPrefixedNumber(mEnd)}m`;
const isDone = false;
</script>

<style lang="scss">
p,
h3 {
  margin: 0;
}
.recipe-row {
  padding: 0 16px;
  margin-bottom: 16px;

  &.isDone {
    border-radius: 12px;
    background: #081b25;

    border: 1px solid #1e353e;
  }

  &.usersRecipe {
    .recipe-row-progress {
      border-color: #49ddaa;
    }
    .recipe-row-token-info h3 {
      color: #fff;
    }
  }

  &.inProgress {
    .recipe-row-progress {
      border-color: #ddcead;
      box-shadow: 0px 0px 2px 0px #ffe603;
    }
  }

  p {
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  &-progress {
    border-radius: 8px;
    height: 4px;
    border-color: #10364a;
    width: 100%;
    border: 1px solid #10364a;
    background: linear-gradient(180deg, #17343e 0%, #010303 100%);
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-token-info {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 20px;

    h3 {
      font-family: Grenze;
      font-weight: 600;
      text-align: left;
      color: #90989d;
    }

    img {
      margin-right: 10px;
      height: 40px;
      width: 40px;
    }
  }

  &-done {
    display: flex;
    align-items: center;

    p {
      font-family: Grenze;
      font-size: 20px;
      font-weight: 500;
      line-height: 29.6px;
      text-align: left;
      color: #49ddaa;
      margin-right: 15px;
    }
  }

  &-time-data {
    p {
      color: #90989d;
      font-family: Grenze;
      font-size: 20px;
      font-weight: 500;
      line-height: 29.6px;
    }

    span {
      color: #fff;
    }
  }

  &-queue {
    display: flex;
    justify-content: space-between;
    width: 45px;
    margin-left: auto;
    margin-bottom: 10px;
  }

  &-close,
  &-time,
  &-link {
    cursor: pointer;

    background-image: url('~/public/img/icons/bone_close.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;

    &:hover {
      opacity: 0.6;
    }
  }

  &-time {
    background-image: url('~/public/img/icons/time.svg');
    cursor: default;
    width: 14px;
    height: 20px;
  }

  &-link {
    background-image: url('~/public/img/icons/link.svg');
  }
}
</style>
