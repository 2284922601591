<template>
  <div class="container">
    <img class="infoIcon" width="24" src="/img/icons/ic_info.svg" alt="" @click="showInfoPopup = true" />
    <el-dialog
      v-if="showInfoPopup"
      v-model="showInfoPopup"
      :title="$t('seasonWhatIsSeason')"
      modal-class="modalSeasonInfo"
      :fullscreen="$device.isMobile"
      width="100%"
      class="tripleBlockInfo"
    >
      <GeneralInfoModal :description="$t('seasonModalDescription')" :config="infoModal" />
    </el-dialog>
    <p v-if="modalView !== SeasonModalView.end && modalView !== SeasonModalView.rewards" class="seasonDuration">
      {{ $t('seasonHolding') }}: {{ start }} - {{ end }}
    </p>
    <p v-if="SEASON_MODAL_VIEW_CONFIG[modalView].descriptionKey" class="seasonDescription">
      {{ $t(SEASON_MODAL_VIEW_CONFIG[modalView].descriptionKey || '') }}
    </p>
    <p
      v-if="SEASON_MODAL_VIEW_CONFIG[modalView].contentTitleKey"
      :class="{ topOffset: modalView === SeasonModalView.start }"
      class="contentTitle"
    >
      {{ $t(SEASON_MODAL_VIEW_CONFIG[modalView].contentTitleKey || '') }}
    </p>

    <div
      v-if="modalView === SeasonModalView.burnTokens"
      class="burnWrapper"
      :class="{ 'new-year': store.isNewYearTheme }"
    >
      <UIModalInnerContentWrapper>
        <div style="display: grid; gap: 25px; width: 100%; grid-template-columns: repeat(5, minmax(0, 1fr))">
          <SeasonBurnCard
            v-for="card of erc20config"
            :key="card?.tokenAddress || ''"
            :season-id="BigNumber(currentSeasonId).toNumber()"
            :season-token="card"
          />

          <SeasonBurnCard
            v-for="card of erc721config"
            :key="`${card?.tokenId}${card?.tokenAddress}` || ''"
            :season-id="BigNumber(currentSeasonId).toNumber()"
            :season-token="card"
            :token-id="card?.tokenId"
          />
        </div>
      </UIModalInnerContentWrapper>
    </div>

    <div v-else-if="modalView === SeasonModalView.leaderboard" class="leaderboardWrapper">
      <SeasonLeaderboardList />
    </div>

    <div v-else-if="modalView === SeasonModalView.start" class="startWrapper">
      <SeasonStart @update-modal-view="onUpdateModalView" />
    </div>

    <div v-else-if="modalView === SeasonModalView.end || modalView === SeasonModalView.rewards" class="endWrapper">
      <SeasonEnd
        @update-modal-view="onUpdateModalView"
        @close="
          () => {
            emit('close');
            checkedSeason = BigNumber(currentSeasonId).minus(1).toNumber();
          }
        "
        @update-checked-season="
          (value: number) => {
            checkedSeason = value;
          }
        "
      />
    </div>

    <SeasonModalSwitcher
      v-if="
        modalView !== SeasonModalView.end &&
        modalView !== SeasonModalView.rewards &&
        modalView !== SeasonModalView.start
      "
      :modal-view="modalView"
      @update-modal-view="onUpdateModalView"
    />
  </div>
</template>

<script lang="ts" setup>
import { SeasonModalView, type TSeasonConfig, type TSeasonConfigItem } from '~/types/season';
import { apiUrls, SEASON_MODAL_VIEW_CONFIG } from '~/utils/constants';
import { BigNumber } from 'bignumber.js';
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { ZeroAddress } from 'ethers';
import type { TNullable } from '~/types/common';
import { infoModal } from './infoModal';

const { apiUrl, blockchain } = useEnvs();
const { getContractReadOnly } = useAbiAccess();
const { address } = useWeb3ModalAccount();
const { t } = useI18n();
const store = useMainStore();

const props = defineProps<{ view?: TNullable<SeasonModalView> }>();
const emit = defineEmits<{
  close: [];
  updateModalHeader: [value: string];
}>();

const seasonContract = await getContractReadOnly('season', blockchain.contracts.season);
const currentSeasonId = await seasonContract?.currentSeason();

const checkedSeason = ref(BigNumber(currentSeasonId).minus(1).toNumber());

const { data } = useFetch<TSeasonConfig>(apiUrls.seasons.config(currentSeasonId), {
  baseURL: apiUrl,
  immediate: true,
  key: 'seasonsConfig',
  watch: [address, () => currentSeasonId, () => store.notificationUpdateVersion],
  async onResponse({ response }) {
    const _erc721: TSeasonConfigItem[] = [];
    erc20config.value = response._data.config.filter((token: TSeasonConfigItem) => {
      const isErc20 = tokenMetaData(blockchain.contracts, token.tokenAddress)?.interface === 'erc20';
      if (!isErc20) _erc721.push(token);
      return isErc20;
    });

    Promise.all(
      _erc721.map(async (contract) => {
        const tokenContract = await getContractReadOnly('earrings', contract.tokenAddress);
        const ownedTokens = await tokenContract?.getTokenIds(address.value || ZeroAddress);
        if (ownedTokens?.toString()?.length > 0) {
          return ownedTokens?.map((token: number) => ({ ...contract, tokenId: token }));
        }
        return contract;
      })
    ).then((config721) => {
      erc721config.value = config721.flat();
    });
  }
});

const seasonEndDate = inject<ComputedRef<Date>>('seasonEndDate');
const showSeasonModal = inject<ComputedRef<boolean>>('showSeasonModal');
const ended = inject<Ref<boolean, boolean>>('ended');

const seasonStartClicked = useCookie(`seasonStartClicked:${address.value}`);

const start = useDateFormat(() => (data.value?.startDate ? new Date(data.value.startDate) : ''), 'DD.MM');
const end = useDateFormat(() => seasonEndDate?.value, 'DD.MM');

const modalView = ref(seasonStartClicked.value ? SeasonModalView.burnTokens : SeasonModalView.start);
const erc20config = ref<TSeasonConfigItem[]>([]);
const erc721config = ref<(TSeasonConfigItem & { tokenId: number })[]>([]);
const showInfoPopup = ref(false);

watch(
  ended!,
  (newValue) => {
    if (newValue) modalView.value = SeasonModalView.end;
  },
  { immediate: true }
);

watch(
  () => props.view,
  (newView) => {
    if (newView) modalView.value = newView;
  },
  { immediate: true }
);

watch(
  [() => showSeasonModal?.value, () => ended?.value],
  ([newShow, newEnded]) => {
    if (!newShow && !newEnded) {
      modalView.value = SeasonModalView.burnTokens;
    } else if (newEnded) {
      modalView.value = SeasonModalView.end;
    }
  },
  { immediate: true }
);

watch(
  [modalView, checkedSeason],
  ([newView, newCheckedSeason]) => {
    switch (newView) {
      case SeasonModalView.burnTokens:
      case SeasonModalView.start:
      case SeasonModalView.leaderboard:
      case SeasonModalView.end:
        emit('updateModalHeader', t(`seasonName${currentSeasonId}`));
        break;
      case SeasonModalView.rewards:
        emit('updateModalHeader', `${t('coreRewardStatus')}: ${t(`seasonName${newCheckedSeason}`)}`);
        break;

      default:
        break;
    }
  },
  { immediate: true }
);
provide('currentSeasonId', currentSeasonId);
provide('modalView', modalView);

const onUpdateModalView = (view: SeasonModalView) => {
  modalView.value = view;
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  font-family: Grenze, sans-serif;

  .infoIcon {
    position: absolute;
    top: 26px;
    cursor: pointer;
  }

  .seasonDuration,
  .seasonDescription,
  .contentTitle {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  .seasonDescription {
    margin: 0 32px;
  }

  .topOffset {
    margin-top: 40px;
  }

  .contentTitle {
    color: #fff;
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .seasonDuration {
    color: #88e1ff;
  }

  .seasonDescription {
    color: rgba(255, 255, 255, 0.8);
    width: 80%;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
.el-dialog.seasonModal {
  overflow: visible;
  margin: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 905px;
  width: 100%;

  &.new-year {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('~/public/img/newYear/bg.png');

    &:after {
      content: '';
      display: block;
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0.9, 0.1, 0.13, 0.35);
    }
  }

  &.minHeight {
    min-height: 60%;
  }

  .el-dialog__title {
    color: #fff;
    padding-right: 0;
  }

  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 10px;

    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;

    .contentTitle {
      color: #fff;
    }
  }

  .modalSeasonInfo {
    overflow: hidden;
  }

  @media screen and (max-width: 900px) {
    width: 85%;
  }
}
</style>
